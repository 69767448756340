<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <i class="header-icon el-icon-search"></i> 搜索
        </template>
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="消息标题:">
            <el-input
                v-model.trim="queryForm.title"
                clearable
                placeholder="请输入消息标题"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" plain size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <div>
      <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">发送</el-button>
      <el-button class="button" type="primary" @click="batchModification" size="small">标记已读</el-button>
      <el-button class="button" type="primary" @click="handleDelete" size="small">全部标记已读</el-button>
    </div>

    <el-table
        :data="list"
        @selection-change="setSelectRows"
        style="width: 100%">
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column
          align="center"
          prop="title"
          label="消息内容"
          width="800">
      </el-table-column>
      <el-table-column
          align="center"
          prop="user_id"
          label="指定用户">
      </el-table-column>
      <el-table-column align="center" label="接收时间">
        <template slot-scope="scope">
          {{ scope.row.create_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="kind"
          :formatter="formatterKind"
          label="消息类型">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="{ row }">
          <el-button type="text" @click="detailsEdit(row)">详情</el-button>
          <el-button type="text" @click="handleEdit(row)">发送</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" />
    <modifyEdit ref="modifyEdit" />
    <detailsEdit ref="detailsEdit" />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import modifyEdit from './components/modifyEdit'
import detailsEdit from './components/detailsEdit'

export default {
  name: 'PM',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: { Edit, modifyEdit, detailsEdit },
  props: [],
  data() {
    return {
      activeName: '1',
      imgShow: true,
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        platform: '',
        is_active: '',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$refs['Edit'].showEdit({ id })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    batchModification() {
      if (this.selectRows.length > 0) {
        const id = this.selectRows.map((item) => item.id).join()
        this.$refs['modifyEdit'].showEdit({ id })
      } else {
        this.$message.error('未选中任何行!')
        return false
      }
    },
    detailsEdit(row) {
      if (row.id) {
        this.$refs['detailsEdit'].showEdit(row)
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const { code, message } = await doDelete({ id: row.id })
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const { code, message } = await doDelete({ id })
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },

    // 消息类型
    formatterKind: function (row) {
      return row.is_check == '0' ? '站内PM'
      : row.is_check == '1' ? 'Email'
      : '暂无'
    },
  }
}

</script>
<style>
  .button {
    margin-top: 20px;
  }

  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
