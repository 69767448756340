<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="是否开启" prop="is_active">
        <el-switch
            v-model="form.is_active"
            active-color="#13ce66"
            active-text="启用"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="暂不启用"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="生效时间" prop="growth_value">
        <el-date-picker
            v-model="query_time"
            size="small"
            :default-time="['00:00:00', '23:59:59']"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="getTimeSection"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      coustate: true,
      query_time: [],
      form: {
        is_active: '',
        growth_value: '',
      },
      rules: {},
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm.start_date = event[0]
        this.queryForm.end_date = event[1]
      } else {
        this.queryForm.start_date = ''
        this.queryForm.end_date = ''
      }
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '批量修改'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      console.log(event)
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const {code, message} = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
