<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="选择用户" prop="user_id">
        <el-select
            v-model="form.user_id"
            clearable
            placeholder="请选择"
            size="small"
            multiple
            filterable
            style="width: 100%"
        >
          <el-option
              v-for="item in apiUser"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="内容" prop="content">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="form.content">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      coustate: true,
      query_time: [],
      apiUser: [],
      form: {
        user_id: '',
        content: '',
      },
      rules: {
        user_id: [
          { required: true, trigger: 'change', message: '请选择用户' },
        ],
        content: [
          { required: true, trigger: 'blur', message: '请选择用户' },
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
    this.requestUser()
  },
  methods: {
    // 获取用户
    async requestUser() {
      const { data } = await matchBranchList()
      this.apiUser = data
    },
    showEdit(row) {
      if (!row) {
        this.title = '群体发送'
      } else {
        this.title = '发送单用户'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const { code, message } = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
