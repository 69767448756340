<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <div v-html=""></div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close" size="small">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      coustate: true,
      query_time: [],
      form: {
        is_active: '',
        growth_value: '',
      },
      rules: {},
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '详情'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      console.log(event)
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
  },
}
</script>
